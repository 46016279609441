.boxes {
  --size: 32px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}
.boxes .box {
  width: var(--size);
  height: var(--size);
  top: 0;
  left: 0;
  position: absolute;
  transform-style: preserve-3d;
}
.boxes .box:nth-child(1) {
  transform: translate(100%, 0);
  animation: box1 var(--duration) linear infinite;
}
.boxes .box:nth-child(2) {
  transform: translate(0, 100%);
  animation: box2 var(--duration) linear infinite;
}
.boxes .box:nth-child(3) {
  transform: translate(100%, 100%);
  animation: box3 var(--duration) linear infinite;
}
.boxes .box:nth-child(4) {
  transform: translate(200%, 0);
  animation: box4 var(--duration) linear infinite;
}
.boxes .box > div {
  --background: #66eee5;
  --top: auto;
  --right: auto;
  --bottom: auto;
  --left: auto;
  --translateZ: calc(var(--size) / 2);
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
}
.boxes .box > div:nth-child(1) {
  --top: 0;
  --left: 0;
}
.boxes .box > div:nth-child(2) {
  --background: #16a69d;
  --right: 0;
  --rotateY: 90deg;
}
.boxes .box > div:nth-child(3) {
  --background: #18d3c6;
  --rotateX: -90deg;
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(200%, 0);
  }
}
@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}
@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}
@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }
  50% {
    transform: translate(200%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}

.loaderParent :global(*) {
  box-sizing: border-box;
}
.loaderParent :global(*):before,
.loaderParent :global(*):after {
  box-sizing: border-box;
}

.loader {
  display: flex;
  position: absolute;
  z-index: 10000;
  min-height: 100vh;
  width: 100vw;
  font-family: Roboto, Arial;
  color: #adafb6;
  align-items: center;
  background: rgba(30, 30, 30, 0.8);
  top: 0;
  flex-direction: column;
  justify-content: space-around;
}
.loader-text {
  position: absolute;
  top: 50%;
  right: 30%;
}
.loader.open {
  display: flex;
}
.loader .dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
}
.loader .dribbble img {
  display: block;
  height: 28px;
}

.uploading-text-wrapper {
  position: absolute;
  z-index: 100001;
  top: calc(50% - 120px);
  text-align: center;
}

.uploading-text {
  font-size: 24px;
  color: white;
  white-space: nowrap;
  text-shadow: 0 0;
  animation: waveText 1600ms linear infinite;
}

@keyframes waveText {
  0%,
  100% {
    text-shadow: 0 0 2px #66eee5, 0 0 5px #66eee5, 0 0 10px #16a69d,
      0 0 20px #16a69d, 0 0 30px #18d3c6;
  }
  50% {
    text-shadow: 0 0 5px #66eee5, 0 0 10px #66eee5, 0 0 15px #16a69d,
      0 0 25px #16a69d, 0 0 35px #18d3c6;
  }
}
